<template>
  <v-container fluid>
    <v-card width="1100" class="mx-auto transparent" elevation="0">
      <v-row>
        <v-col cols="12" sm="5" class="py-0 ml-0 mr-4 pt-0" @click="id = []">
          <v-autocomplete
            :items="periods"
            :filter="customFilter"
            v-model="period"
            item-text="name"
            label="Período"
            single-line
          ></v-autocomplete>
        </v-col>
        <v-col v-if="showCalendar">
          <Calendar
            type="month"
            :maximumDate="today"
            minimumDate="2021-10-01"
            @selectedDate="customPeriod = $event"
          />
        </v-col>
      </v-row>

      <TransactionTable
        :months="selectedDates"
        :openPanel="id"
        :isResellerTransaction="true"
      />
    </v-card>
  </v-container>
</template>

<script>
import TransactionTable from "@/components/main/shared/TransactionTable";
import Calendar from "@/components/main/shared/Calendar";
import * as moment from "moment";
import { formatPeriod } from "@/helpers/services/transactions";

export default {
  components: {
    TransactionTable,
    Calendar,
  },

  data() {
    return {
      id: null,
      period: "",
      customPeriod: [],
      periods: [
        { name: "Este mês", id: 0 },
        { name: "Mês passado", id: 1 },
        { name: "Últimos três meses", id: 2 },
        { name: "Este ano", id: 3 },
        //{ name: "Ano anterior", id: 4 },
        { name: "Personalizado", id: 5 },
        // { name: 'Todo o período', id: 6 },
      ],
      showCalendar: false,
      today: "",
      selectedDates: [],
    };
  },

  beforeMount() {
    this.id = [this.$route.query.id];
  },

  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.period = "Últimos três meses";
  },

  watch: {
    period() {
      [this.selectedDates, this.showCalendar] = this.formatPeriod(this.period);
    },

    customPeriod() {
      this.formatCustomPeriod();
    },
  },

  methods: {
    formatPeriod,

    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    formatCustomPeriod() {
      this.id = [];
      this.selectedDates = [];

      let firstMonth = moment(this.customPeriod[0])
        .locale("pt-br")
        .format("YYYY-MM");
      let currentMonth = moment(this.customPeriod[1])
        .locale("pt-br")
        .format("YYYY-MM");
      let auxiliary = currentMonth;

      let i = 0;
      while (currentMonth >= firstMonth) {
        auxiliary = moment(currentMonth)
          .locale("pt-br")
          .format("MMMM [de] YYYY");
        auxiliary = auxiliary
          .substring(0, 1)
          .toUpperCase()
          .concat(auxiliary.substring(1));
        this.selectedDates.push({
          id: i,
          label: auxiliary,
          requestDate: currentMonth,
        });
        currentMonth = moment(currentMonth)
          .locale("pt-br")
          .add(-1, "months")
          .format("YYYY-MM");
        i++;
      }
    },
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #f5f5f5;
}
</style>
